<template>
    <main>
        <header
            class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10"
        >
            <div class="container-fluid">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon">
                                    <i data-feather="layout"></i>
                                </div>
                                EPS
                            </h1>
                            <div class="page-header-subtitle">Lista Eps</div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="container-fluid mt-n10">
            <div class="card">
                <div class="card-body">
                    <data-grid :data="pagination"
                               @changePage="changePage($event)"
                               @perPageChange="perPageChange($event)"
                               @search="search($event)">
                        <template #grid>
                            <grid-table>
                                <template #head>
                                    <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                </template>
                                <template #body>
                                    <grid-row v-for="(eps, i) in pagination.data" :key="`d_${i}`">
                                        <grid-cell class="small">
                                            {{ eps.IDEPS }}
                                        </grid-cell>
                                        <grid-cell class="small">
                                            {{ eps.NIT }}
                                        </grid-cell>
                                        <td class="small">
                                            {{ eps.DESEPS }}
                                        </td>
                                        <grid-cell class="small">
                                            {{
                                                eps.municipio != null
                                                    ? eps.municipio.departamento.nombre
                                                    : "-"
                                            }}
                                            -
                                            {{ eps.municipio != null ? eps.municipio.nombre : "-" }}
                                        </grid-cell>
                                        <grid-cell class="small">
                                            {{ eps.DIRECCION }}
                                        </grid-cell>
                                        <grid-cell class="small">
                                            {{ eps.TELEFONO }}
                                        </grid-cell>
                                        <td class="small">
                                            {{ eps.email }}
                                        </td>
                                        <grid-cell class="small">
                                            <router-link
                                                class="btn btn-transparent btn-icon"
                                                :to="'/eps/edit/' + eps.id">
                                                <i class="fas fa-edit"></i>
                                            </router-link>
                                        </grid-cell>
                                    </grid-row>
                                </template>
                            </grid-table>

                        </template>
                        <template #action-button>
                            <router-link :to="{name: 'eps.create'}" class="btn btn-info shadow-sm mb-2 btn-sm">
                                <i class="fa fa-plus text-white-50"></i> Nueva
                            </router-link>
                        </template>
                    </data-grid>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import EpsService from "../../services/epsService";
import DataGrid from "../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../components/common/utilities/DataGrid/GridCell";

export default {
    components: {GridCell, GridRow, GridCellHeader, GridTable, DataGrid},
    created() {
        this.getEpsList();
    },
    data() {
        return {
            eps_list: [],
            headers : [
                'Codigo',
                'Nombre',
                'Departamento',
                'Municipio',
                'Direccion',
                'Telefono',
                'Correo Electronico',
                ''
            ],
            pagination : {
                data : [],
                search : "",
                per_page : 5,
                page : 1,
                current_page : 1,
                total : 0,
            }
        };
    },
    methods: {
        async getEpsList() {
            this.LoaderSpinnerShow();
            try {

                const response = (await EpsService.list(this.pagination.search, this.pagination))?.data;

                this.pagination.data = response.data;
                this.pagination.total = response.total;
                this.pagination.current_page = response.current_page;

                this.LoaderSpinnerHide();
            } catch (ex) {
                console.error(ex);
                this.LoaderSpinnerHide();
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getEpsList();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getEpsList();
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getEpsList();
        },
    },
};
</script>
